/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-big-calendar/lib/css/react-big-calendar.css";

::-moz-selection {
	/* Code for Firefox */
	color: white;
	background: #6366f1;
}

::selection {
	color: white;
	background: #6366f1;
}

#ck-content h1 {
	font-size: 2em;
	font-weight: bold;
}
#ck-content h2 {
	font-size: 1.5em;
	font-weight: bold;
}
#ck-content h3 {
	font-size: 1.17em;
	font-weight: bold;
}
#ck-content h4 {
	font-size: 1em;
	font-weight: bold;
}
#ck-content ul {
	list-style-type: disc;
	list-style-position: inside;
}
#ck-content ol {
	list-style-type: decimal;
	list-style-position: inside;
}
#ck-content ul ul,
#ck-content ol ul {
	list-style-type: circle;
	list-style-position: inside;
	margin-left: 15px;
}
#ck-content ol ol,
#ck-content ul ol {
	list-style-type: lower-latin;
	list-style-position: inside;
	margin-left: 15px;
}
#ck-content a {
	text-decoration: underline;
}

html {
	background-color: #6366f1;
}
body {
	background-color: white;
}

/* Styling the Calendar Toolbar */

.rbc-toolbar-label {
	order: 1 !important;
	font-size: large;
	font-weight: 600;
	text-align: left !important;
	width: max-content;
	padding: 0 !important;
}
.rbc-btn-group {
	order: 2 !important;
}
.rbc-toolbar {
	justify-content: space-around !important;
	width: 100%;
}
